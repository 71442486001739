import { useEffect, useRef, useState } from 'react';
import { safeLocalStorage } from '@toss/storage';
import { LAST_BANNER_LOCAL_STORAGE_KEY } from '@zep/consts/keys';
import { SupportedLocale, SupportedLocaleMap } from '@zep/consts/localize';
import { useEffectOnce, useLocale } from '@zep/hooks';

import { BannerType } from './StickyBanner';

const BANNER_ROTATION: SupportedLocaleMap<'ko' | 'en' | 'ja', BannerType[]> = {
  ko: ['REFERRER_EVENT'],
  en: ['RESELLER_PROGRAM', 'EARLY_ACCESS'],
  ja: ['2_WEEKS_FREE_TRIAL'],
};

function getNewBannerType(
  lastBannerType: string | null,
  locale: SupportedLocale,
): BannerType {
  const bannerRotation = BANNER_ROTATION[locale] ?? BANNER_ROTATION.en;
  if (lastBannerType === null) return bannerRotation[0];
  return (
    bannerRotation[bannerRotation.indexOf(lastBannerType as BannerType) + 1] ??
    bannerRotation[0]
  );
}

export const useBannerType = () => {
  const { locale } = useLocale();
  const [isLoadingBannerType, setIsLoadingBannerType] = useState(true);
  const lastBannerTypeRef = useRef<string | null>(null);
  const newBannerType = getNewBannerType(lastBannerTypeRef.current, locale);

  useEffectOnce(() => {
    lastBannerTypeRef.current = safeLocalStorage.get(
      LAST_BANNER_LOCAL_STORAGE_KEY,
    );
    setIsLoadingBannerType(false);
  });

  useEffect(() => {
    if (isLoadingBannerType) return;
    safeLocalStorage.set(LAST_BANNER_LOCAL_STORAGE_KEY, newBannerType);
  }, [locale, isLoadingBannerType, newBannerType]);

  return {
    isLoadingBannerType,
    newBannerType,
  };
};
