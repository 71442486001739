;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"011ef13b6a940496bd2355834a1dc3bca911b679"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { createBeforeSend } from './src/utils/sentry';
import baseOptions from './sentry.base.config';

Sentry.init({
  ...baseOptions,
  beforeSend: createBeforeSend,
  enableTracing: false, // 성능 하락 방지를 위해 비활성화
});
