import { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary } from '@toss/error-boundary';
import { useTimeout } from '@toss/react';
import { getGetNoticesQueryKey, useGetNotices } from '@zep/api';
import { NoSSR } from '@zep/components/NoSSR';
import { getSupportedCountryCode } from '@zep/consts/localize';
import { useCountryCode } from '@zep/hooks/useCountryCode';
import { PopupNotice } from '@zep/module/Notice/PopupNotice';
import { TextNotice } from '@zep/module/Notice/TextNotice';
import { useRouter } from 'next/router';
import { NoticeManager } from './NoticeStorage';
const disabledPathNames = ['/admin', '/auth/google-callback', '/quiz/[id]', '/sign-up/', '/login'];
export const _NoticeContainer = () => {
  const router = useRouter();
  const {
    countryCode
  } = useCountryCode();
  const [enabled, setEnabled] = useState(false);
  const location = router.pathname.startsWith('/play') ? 'PLAY' : 'WEB';
  const {
    data,
    isSuccess
  } = useGetNotices({
    countryCode: getSupportedCountryCode(countryCode),
    location
  }, {
    query: {
      throwOnError: false,
      enabled: enabled,
      refetchOnMount: true,
      refetchIntervalInBackground: false,
      refetchInterval: 1000 * 60 * 10,
      //10분
      refetchOnWindowFocus: true
    }
  });

  // 공지가 다른 실행 컨텍스트에 방해되지 않게 하기 위해 딜레이 추가
  useTimeout(() => {
    setEnabled(true);
  }, location === 'PLAY' ? 1000 * 5 : 1000 * 1.5);
  const filteredNotices = useMemo(() => {
    return (data?.data ?? []).filter(notice => NoticeManager.isNoticeVisible(String(notice.id)));
  }, [data]);
  if (!isSuccess || !enabled) return null;
  return filteredNotices.map(item => {
    switch (item.type) {
      case 'MODAL':
        {
          return <PopupNotice key={item.id || item.type} {...item} />;
        }
      case 'TEXT':
        {
          return <TextNotice key={item.id || item.type} {...item} />;
        }
    }
  });
};
export const NoticeContainer = () => {
  const router = useRouter();
  const client = useQueryClient();
  const [isPending, setIsPending] = useState(false);
  const [isRouteChanging, setIsRouteChanging] = useState(false);
  const {
    countryCode
  } = useCountryCode();
  useEffect(() => {
    const handleRouteChangeStart = (currentPath: string) => {
      const isToPlay = currentPath.startsWith('/play');
      const currentPathName = new URL(currentPath, window.location.href).pathname;
      if (router.pathname === currentPathName) return;
      setIsRouteChanging(true);
      setIsPending(true);
      client.invalidateQueries({
        queryKey: getGetNoticesQueryKey({
          countryCode: getSupportedCountryCode(countryCode),
          location: isToPlay ? 'WEB' : 'PLAY'
        })
      }).then(() => {
        setIsPending(false);
      });
    };
    const handleRouteChangeComplete = () => {
      setIsRouteChanging(false);
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [client, countryCode, router.events, router.pathname]);
  if (disabledPathNames.some(pathname => router.pathname.startsWith(pathname)) || isRouteChanging || isPending) {
    return null;
  }
  return <NoSSR data-sentry-element="NoSSR" data-sentry-component="NoticeContainer" data-sentry-source-file="NoticeContainer.tsx">
      <ErrorBoundary onError={e => {
      console.error(e);
      Sentry.captureException(e, {
        fingerprint: ['notice']
      });
    }} renderFallback={() => null} data-sentry-element="ErrorBoundary" data-sentry-source-file="NoticeContainer.tsx">
        <_NoticeContainer data-sentry-element="_NoticeContainer" data-sentry-source-file="NoticeContainer.tsx" />
      </ErrorBoundary>
    </NoSSR>;
};