import { SupportedLocaleMap } from '@zep/consts/localize';

import { BannerType } from './StickyBanner';

export const BANNER_DATA: SupportedLocaleMap<
  'ko' | 'en' | 'ja' | 'id' | 'th',
  Partial<
    Record<
      BannerType,
      { imgSrc: string; linkHref: string; loginRequired: boolean }
    >
  >
> = {
  ko: {
    '2_WEEKS_FREE_TRIAL': {
      imgSrc: '/assets/sticky_banner_5_ko.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ko/articles/5e8edb41-ZEP-QUIZ-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EC%95%88%EB%82%B4',
      loginRequired: true,
    },
    RESELLER_PROGRAM: {
      imgSrc: '/assets/reseller_program_kr.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ko/articles/ZEP-QUIZ-%EB%A6%AC%EC%85%80%EB%9F%AC-%ED%94%84%EB%A1%9C%EA%B7%B8%EB%9E%A8-f09d6317',
      loginRequired: false,
    },
    REFERRER_EVENT: {
      imgSrc: '/assets/referrer_event_kr.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ko/articles/ZEP-QUIZ-%EC%8B%A0%ED%95%99%EA%B8%B0-%EC%B4%88%EB%8C%80-%EC%9D%B4%EB%B2%A4%ED%8A%B8-7c32e082',
      loginRequired: false,
    },
  },
  en: {
    EARLY_ACCESS: {
      imgSrc: '/assets/early_access_banner_en.webp',
      linkHref: 'https://tally.so/r/npkMNb',
      loginRequired: false,
    },
    RESELLER_PROGRAM: {
      imgSrc: '/assets/reseller_program_en.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/en/articles/ZEP-QUIZ-Reseller-Program-95bc7619',
      loginRequired: false,
    },
  },
  ja: {
    '2_WEEKS_FREE_TRIAL': {
      imgSrc: '/assets/sticky_banner_5_ja.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ja/articles/5e8edb41-ZEP-QUIZ-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EC%95%88%EB%82%B4',
      loginRequired: true,
    },
    //일본 reseller_program 배너 추가 예정
    // RESELLER_PROGRAM: {
    //   imgSrc: '/assets/reseller_program_en.png',
    //   linkHref:
    //     'https://docs.channel.io/zepquiz/en/articles/ZEP-QUIZ-Reseller-Program-95bc7619',
    //   loginRequired: false,
    // },
  },
  id: {
    EARLY_ACCESS: {
      imgSrc: '/assets/early_access_banner_id.webp',
      linkHref: 'https://tally.so/r/mKagA7',
      loginRequired: false,
    },
    RESELLER_PROGRAM: {
      imgSrc: '/assets/reseller_program_en.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/en/articles/ZEP-QUIZ-Reseller-Program-95bc7619',
      loginRequired: false,
    },
  },
  th: {
    EARLY_ACCESS: {
      imgSrc: '/assets/early_access_banner_th.webp',
      linkHref: 'https://tally.so/r/wkkpXM',
      loginRequired: false,
    },
    RESELLER_PROGRAM: {
      imgSrc: '/assets/reseller_program_th.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/en/articles/%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%81%E0%B8%81%E0%B8%A3%E0%B8%A1%E0%B8%95%E0%B8%A7%E0%B9%81%E0%B8%97%E0%B8%99%E0%B8%88%E0%B8%B3%E0%B8%AB%E0%B8%99%E0%B8%B2%E0%B8%A2-ZEP-QUIZ-f09d6317',
      loginRequired: false,
    },
  },
};

export const BANNER_CLASSNAMES: Record<BannerType, string> = {
  '2_WEEKS_FREE_TRIAL': 'bg-gradient-to-l from-[#AF70FF] to-[#3DA9FF]',
  EARLY_ACCESS: 'bg-[#7EC753]',
  RESELLER_PROGRAM: 'bg-[#5C7AFF]',
  REFERRER_EVENT: 'bg-[#3BC3A1]',
};
