// @ts-check

/** @type {import('./localize').SupportedCountryCode[]} */
const SUPPORTED_COUNTRY_CODES_ARR = [
  'KR',
  'US',
  'JP',
  'ID',
  'TH',
  'PH',
  'VN',
  'MY',
  'UZ',
  'FR',
  'KH',
  'RU',
  'CN',
  'TW',
];

// 언어가 추가되는 변수입니다.
/** @type {import('./localize').SupportedLocalesObject} */
const SUPPORTED_LOCALES_OBJ = {
  ko: 'ko',
  en: 'en',
  ja: 'ja',
  id: 'id',
  th: 'th',
  fil: 'fil',
  vi: 'vi',
  ms: 'ms',
  uz: 'uz',
  fr: 'fr',
  km: 'km',
  ru: 'ru',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
};

/** @type {import('./localize').SupportedLocalesArray} */
const SUPPORTED_LOCALES_ARR = Object.values(SUPPORTED_LOCALES_OBJ);

/** @type {import('./localize').CountryCodeToLocaleMap} */
const COUNTRY_CODE_TO_LOCALE_MAP = {
  KR: 'ko',
  US: 'en',
  JP: 'ja',
  ID: 'id',
  TH: 'th',
  PH: 'fil',
  VN: 'vi',
  MY: 'ms',
  UZ: 'uz',
  FR: 'fr',
  KH: 'km',
  RU: 'ru',
  CN: 'zh-CN',
  TW: 'zh-TW',
};

/** @type {import('./localize').LocaleToCountryCodeMap} */
const LOCALE_TO_COUNTRY_CODE_MAP = {
  ko: 'KR',
  en: 'US',
  ja: 'JP',
  id: 'ID',
  th: 'TH',
  fil: 'PH',
  vi: 'VN',
  ms: 'MY',
  uz: 'UZ',
  fr: 'FR',
  km: 'KH',
  ru: 'RU',
  'zh-CN': 'CN',
  'zh-TW': 'TW',
};

module.exports = {
  SUPPORTED_COUNTRY_CODES_ARR,
  SUPPORTED_LOCALES_OBJ,
  SUPPORTED_LOCALES_ARR,
  COUNTRY_CODE_TO_LOCALE_MAP,
  LOCALE_TO_COUNTRY_CODE_MAP,
};
