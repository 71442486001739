import { useMemo } from 'react';
import { NoSSR } from '@zep/components';
import { SupportedLocale } from '@zep/consts/localize';
import { SUPPORTED_LOCALES_ARR } from '@zep/consts/localize-js';
import { useLocale } from '@zep/hooks';
import { HomeLogo } from '@zep/layout';
import { HeaderContainer } from '@zep/layout/Header/HeaderContainer';
import { MobileDrawer } from '@zep/layout/Header/MobileDrawer/MobileDrawer';
import { AuthButton, CreateNewQuizButton } from '@zep/layout/Header/UserManagementButton';
import { cn } from '@zep/utils';
import { useTranslation } from 'next-i18next';
import { GnbButtonInfo, LeftButtons } from './LeftButtons';
import { EnterCodeButton, ExploreButton } from './PageNavigatorButton';
export function GnbHeader() {
  const {
    t
  } = useTranslation(['common']);
  const {
    locale
  } = useLocale();
  const gnbButtonInfo: Record<'pricing' | 'public' | 'notice' | 'guide' | 'teacher_reviews', GnbButtonInfo> = useMemo(() => {
    return {
      pricing: {
        alias: '프라이싱',
        link: '/pricing',
        target: '_self',
        icon: '/assets/icon_magnifying_glass.svg',
        text: t('common.header.pricing'),
        track: '',
        hideLocales: [],
        eventName: 'gnb_pricing_click'
      },
      public: {
        alias: '둘러보기',
        link: '/public',
        target: '_self',
        text: t('header.explore.button'),
        track: '',
        hideLocales: [],
        eventName: 'gnb_public_click'
      },
      guide: {
        alias: '가이드',
        link: t('common.header.guideLink'),
        target: '_blank',
        text: t('common.header.userGuide'),
        track: '',
        hideLocales: [],
        eventName: 'gnb_guide_click'
      },
      notice: {
        alias: '공지사항',
        link: t('common.header.noticeLink'),
        target: '_blank',
        text: t('common.header.notice'),
        track: '',
        hideLocales: ['ja'],
        eventName: 'gnb_notice_click'
      },
      teacher_reviews: {
        alias: '선생님 후기',
        link: 'https://zep-quiz-teachers-review.super.site/',
        target: '_blank',
        text: '선생님 후기',
        track: '',
        hideLocales: (SUPPORTED_LOCALES_ARR.filter(locale => locale !== 'ko') as SupportedLocale[]),
        eventName: 'gnb_review_click'
      }
    };
  }, [t]);
  return <HeaderContainer data-sentry-element="HeaderContainer" data-sentry-component="GnbHeader" data-sentry-source-file="GnbHeader.tsx">
      <div className="flex items-center gap-[24px]">
        <HomeLogo location={'header'} data-sentry-element="HomeLogo" data-sentry-source-file="GnbHeader.tsx" />
        <div className={cn('gap hidden items-center gap-sm md:flex', locale === 'ko' && 'gap-3xs')}>
          <LeftButtons gnbButtonInfos={gnbButtonInfo} data-sentry-element="LeftButtons" data-sentry-source-file="GnbHeader.tsx" />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <div className={'flex md:hidden'}>
          <ExploreButton.Icon data-sentry-element="unknown" data-sentry-source-file="GnbHeader.tsx" />
        </div>
        <AuthButton data-sentry-element="AuthButton" data-sentry-source-file="GnbHeader.tsx" />
        <div className={'hidden gap-2xs md:flex md:items-center'}>
          <EnterCodeButton type={'normal'} data-sentry-element="EnterCodeButton" data-sentry-source-file="GnbHeader.tsx" />
          <CreateNewQuizButton type={'normal'} data-sentry-element="CreateNewQuizButton" data-sentry-source-file="GnbHeader.tsx" />
        </div>

        <NoSSR fallback={<MobileDrawer.TriggerContent />} data-sentry-element="NoSSR" data-sentry-source-file="GnbHeader.tsx">
          <MobileDrawer
        //모바일 drawer에서는 선생님 후기 제외
        gnbButtonInfos={{
          pricing: gnbButtonInfo.pricing,
          public: gnbButtonInfo.public,
          guide: gnbButtonInfo.guide,
          notice: gnbButtonInfo.notice
        }} footerBottomAccessory={<>
                <CreateNewQuizButton type={'drawer'} />
                <EnterCodeButton type={'drawer'} />
              </>} data-sentry-element="MobileDrawer" data-sentry-source-file="GnbHeader.tsx" />
        </NoSSR>
      </div>
    </HeaderContainer>;
}